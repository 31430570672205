/* TODO Add site wide styles */

video {
  width: 850px;
  height: 490px;
}

#root {
  height: 100%;
}

body {
  font-family: 'Lato', sans-serif;
}


a {
  text-decoration: none;
  color: black;
}
