/* .splash-page-top-animation.party {
    background-image: url('../../media/2bef1e803125126ff1663c8c2f8a7a8b.gif');
    height: 600px;
    width: 1600px;
} */


.splash-top-section {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-size: 100px;
}


.splash-top-section.party {
    background-image: url('../../media/dancing-group.gif');
}

.inactive-party-ball {
    height: 64.15vh;
}


.groovetube-text-header {
    font-family: 'Keep on Truckin';
    margin-bottom: 0;
    font-size: 10rem;
    margin-top: 0;
    letter-spacing: 0.05em;
    text-align: center;
    color: #F9f1cc;
    text-shadow: 5px 5px 0px #FFB650,
        10px 10px 0px #FFD662,
        15px 15px 0px #FF80BF,
        20px 20px 0px #EF5097,
        25px 25px 0px #6868AC,
        30px 30px 0px #90B1E0;
}


.second-section-groovetube-header {
background: linear-gradient(45deg, #7F71F7, #FAD961);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 5rem; 
    border-radius: 30px;
    position: absolute;
    flex-direction: column;
    background-image: url('../../media/bg5.jpeg');
    background-size: cover;
        background-position: center center;
    font-weight: 300;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    top: 20%;
    color: white;
    max-width: 30rem;
    padding: 2rem 2rem;
}

.second-section-button {
    background: none;
    border: 1px solid white;
    border-radius: 12px;
    color: white;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
}

.second-section-button.one {
    height: 3rem;
}

.second-section-groovetube-header {
    line-height: 25px;
}

.second-section-button.two {
    height: 2rem;
    padding: 0 1rem;
}


.splash-logo {
    min-width: 200px;
    width: 25vw;
    height: 40vh;
    align-self: center;
    z-index: -1;
}


.splash-logo-and-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    width: 50%;
}



.splash-all-wrapper {
    display: flex;
    flex-direction: column;
}


.welcome-to-groovetube {
        color: #ff6db6;
font-family: 'Lato', sans-serif;
            text-decoration: none;
            font-size: 60px;
            font-weight: bold;
            margin-top: 0;
}




.header-logo-splash-span {
    display: flex;
    align-items: center;
}

.header-nav-logo-render {
    height: 20vh;
}



.go-to-app-button {
    height: 4rem;
    width: 12rem;
    margin-bottom: 2rem;
    background-color: #f9f1cc00; 
    color: #F9F1CC;;
    border: 1px solid #F9F1CC;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.3s ease;
    font-weight: bold;
        /* text-shadow: 5px 5px 0px #FFB650,
                2.5px 2.5px 0px #FFD662,
                3.75px 3.75px 0px #FF80BF,
                5px 5px 0px #EF5097,
                6.25x 6.25px 0px #6868AC,
                7.5px 7.5px 0px #90B1E0; */
    /* background-image: url('../../media/bg4.jpeg');
    background-size: cover;
        background-position: center center; */

}


.splash-second-section-2 {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    position: relative;
}


.splash-second-section-2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
}


#video-background-splash {
    position: absolute;
  right: 0;
  /* bottom: 0; */
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}


.splash-db-test {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url('../../media/background.jpeg'); */
    border-radius: 30px;
    padding: 0 4rem;
}

#video-background-splash-2 {
position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

#video-background-splash::before{
    background-color: (0, 0, 0, 0.3);
}


.main-top-img-section {
    display: flex;
    width: 100vw;

}


.main-top-img {
    width: 15vw;
}

.indiv-img-top-sec {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    gap: 5rem;
}


.audio-play-button {
    align-self: flex-end;
    font-weight: bold;
    position: fixed;
    transition: transform 0.3s ease;
    font-weight: bold;
    background-color: #f9f1cc00;
    border: 1px solid #f9f1cc;
    color: #f9f1cc;
    font-family: 'Lato', sans-serif;
    border-radius: 30px;
    height: 3rem;
    width: 8rem;
    cursor: pointer;
    margin-right: 2rem;
    margin-top: 1rem;
    z-index: 999;
}

.go-to-app-button:hover {
    transform: scale(1.1);
}

.audio-play-button:hover {
    transform: scale(1.1);
}

.splash-about-me {
    margin-top: 6rem;
    margin-left: 6rem;
    height: 22rem;
    width: 18rem;
    bottom: 6rem;
    left: 2rem;
    position: absolute;
background-image: url('../../media/bg5.jpeg');
    background-size: cover;
    background-position: center center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
    margin-right: 10rem;
    bottom: 0;
}


.carousel-container {
    overflow: hidden;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    width: 50rem;
    height: 10rem;
    background: linear-gradient(45deg, #7F71F7, #FAD961);
    border-radius: 20px;
}

.carousel-slides {
    display: flex;
    align-items: center;
    animation: carousel-slide 20s linear infinite both;
    animation-fill-mode: both;
}

.carousel-slide {
    margin-right: 6rem;
}



.carousel-image {
    width: 8vw;
}


.splash-about-me:hover {
    transform: scale(1.1);
}

.me-img {
    width: 200px;
    height: 200px;
    object-fit:cover;
    border-radius: 10px;
}


.splash-div-socials {
    display: flex;
    gap: 2rem;

}


#splash-social-icon {
    color: #ffffff;
    font-size: 20px;
}

  @keyframes carousel-slide {
      0% {
          transform: translateX(-150%);
      }

      100% {
          transform: translateX(100%);
      }
  }



  



