.video-search-page {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    flex-direction: column;
    align-items: center;
}

.search-bar-input {
flex: 1;
    
    padding: 12px 18px;
    border-radius: 18px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 16px;
    background-color: inherit;
    border: none
}

/* 
.search-bar-wrapper {
    width: 30vw;
    display: flex;
    position: relative;
    align-items: center;
} */

.search-bar-form {

display: flex;
    align-items: center;
    border-radius: 2px;
    width: 30vw;
}

.search-suggestion-container {
    position: absolute;
    background-color: white;
    border: 1px solid rgb(201, 199, 199);
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    border-radius: 12px;
    z-index: 9999;
    margin-top: 2px;
    padding: 0 1rem;
}

.search-container-div {
    border: 1px solid #CCCCCC;
    border-radius: 22px;
}

.search-button {
border: none;
    padding: 12px 16px;
    border-radius: 2px;
    font-size: 16px;
    background-color: #F8F8F8;
    color: black;
    width: 4rem;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
}