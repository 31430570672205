.below-vid-above-comments-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.post-date-and-views-div {
    display: flex;
        align-items: center;
        gap: 6px;
}


.video-page-wrapper {
    margin-left: 4.5rem;
    display: flex;
    padding-bottom: 10rem;
    margin-top: 6rem;
}

.individual-comment-container .comment-delete-and-edit {
    display: none;
}



.individual-comment-container:hover .comment-delete-and-edit {
    display: flex;
    gap: 10px;
}

.individual-comment-container:hover .comment-delete-and-edit button {
    border: 1px solid #0A5ED4;
    color: #0A5ED4;
    height: 2rem;
    width: 4rem;
    border-radius: 14px;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    gap: 10px;
}


.individual-comment-container:hover .comment-delete-and-edit button:hover {
    border: 1px solid #0A5ED4;
    color: white;
    border-radius: 14px;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    background-color: #0A5ED4;
    gap: 10px;
}



.comment-user-info-wrapper {
    display: flex;
    gap: 20px;
}

.comment-user-info {
    display: flex;
    gap: 5px;
    align-items: center;
}

.comment-date-post {
    color: #606060;
    font-size: 12px;
}

.comments-number-header {
    display: flex;
    align-items: center;
    gap: 5px;
}


.comment-form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.video-page-comments {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
}


.individual-comment-container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.comment-bottom-section-user-and-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    word-break: break-word;
        max-width: 40rem;;
}


.header-likes-container {
    display: flex;
        align-items: center;
        justify-content: space-between;
}

.likes-number-and-thumb-logo {
    display: flex;
    align-items: center;
    margin-right: 8rem;
    gap: 7px;
    border: 1px solid;
    height: 1.8rem;
    width: 3rem;
    border-radius: 12px;
    background-color: #F2F2F2;
    border: none;
}


#liked-comment-thumb {
    color: #FF66C3;
    transition: background-color 0.2s;
    animation: pop 1s ease-in-out;
    margin-left: 10px;
    cursor: pointer;
}



@keyframes pop {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.0);
  }
}

#unliked-comment-thumb {
    color: rgba(160, 157, 157, 0.528);
    margin-left: 10px;
    cursor: pointer;
}


.video-description-container {
    background-color: #F2F2F2;
    border-radius: 16px;
    padding: .8rem .8rem;
    display: flex;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
}

.video-views-description-text {
    font-weight: bold;
    font-size: 12px;
}


.leave-comment-pfp-and-input {
    display: flex;
    width: 100%;
}


.video-description-container:hover {
    background-color: #E5E5E5;
}

.comment-form {
    width: 96%;
}


.video-page-comment-section-container {
    margin-top: 1rem;
}

.comment-input {
    border: none;
    border-bottom: 1px solid #E5E5E5;
    margin-left: 1rem;
    outline: none;
    width: 96%;
    font-family: 'Lato', sans-serif;
}

.leave-comment-button-container {
    display: flex;
    justify-content: flex-end;
}

.leave-comment-button-container > button {
    border: none;
    background-color: #0A5ED4;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: white;
    height: 2.5rem;
    padding: 0 1rem;
    border-radius: 18px;
    cursor: pointer;
}

.video-owner-pic-and-name {
    display: flex;
    align-items: center;
    gap: 15px;
}

.user-pfp-subscribe-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leave-comment-button-container > button:hover {
    background-color: #0356BE;
}


.leave-comment-button-container > button:active {
background-color: #3F78CC;
}




.comment-user-name {
    font-weight: 700;
}



.video-page-comment-user-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}


.video-section-scroller-right {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
    gap: 6px;
}

.side-card-video-title {
    font-weight: 700;
    font-size: 13px;
}

.video-page-side-vid-container {
    display: flex;
    gap: 10px;
    max-width: 24vw;
}


.other-videos-header {
    margin-top: 0;
}


.side-video-time-stamp {
    color: #606060;
    font-size: 12px;
}

.side-video-thumbnail {
    height: 7rem;
    width: 12rem;
    border-radius: 12px;
    object-fit: cover;
}


.side-video-card-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.side-user-profile-picture {
    height: 40px;
    width: 40;
}

.side-video-card-lower-information {
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.side-card-video-username {
    color: #606060;
    font-size: 12px;
}

.side-card-video-views {
    color: #606060;
    font-size: 12px;
}


.edit-comment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7rem;
    gap: 60px;
}

.edit-comment-form > button {
    background-color: #085ED4;
    width: 50%;
    align-self: center;
    color: white;
    font-family: 'Lato', sans-serif;
    border: none;
    border-radius: 12px;
    height: 2rem;
    width: 6rem;
    cursor: pointer;
    font-weight: bold;
}


.edit-comment-form > button:hover {
    background-color: #0850b6;
}


.edit-comment-form>button:active {
    background-color: #0469f7;
}


.edit-comment-modal-header {
    text-align: center;
}


.edit-comment-textarea {
    border-radius: 8px;
    padding-left: 10px;
    min-height: 6rem;
    padding-top: 10px;
}


.delete-comment-modal-wrapper {
    display: flex;
}


.delete-comment-modal-form {
    display: flex;
    flex-direction: column;
    padding: 3rem 4rem 3rem 4rem;
    align-items: center;
}

.delete-comment-modal-buttons {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    gap: 1rem;
}

.delete-comment-modal-buttons > button {
    background-color: #085ED4;
    cursor: pointer;
    color: white;
    border-radius: 12px;
    height: 2rem;
    width: 6rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    border: none;
}

.delete-comment-modal-buttons>button:hover {
    background-color: #0850b6;
}


.delete-comment-modal-buttons>button:active {
    background-color: #0469f7;
}


#edit-button-comment-save-button {
    cursor: pointer;
}