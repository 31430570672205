.login-page-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}


.outer-login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px #D9DCE0 solid;
  height: 25rem;
  width: 23rem;
  border-radius: 8px;
}

.login-error {
  color: red;
  margin: 0 0;
  margin-bottom: 6px;
}


.login-inputs-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 80%;
  height: 80%
}

/* .email-and-password-login-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
} */


.login-input {
  position: relative;
  height: 35px;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 15px;
  border: 1px solid #dfdede;
  font-family: 'Lato', sans-serif;
  padding-left: 10px;
}


.login-input::placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
}


.login-form-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-form-button-wrapper > button {
  background-color: #1B72E8;
  color: white;
  border: none;
  height: 2rem;
  width: 4rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Lato', sans-serif;

}


.login-form-button-wrapper > button:hover {
  background-color: #0c60d6;
}


.login-form-button-wrapper > button:active {
  background-color: #0a54ba;
}


.login-form-create-account {
  color: #1B72E8;
  cursor: pointer;
}


.demo-login-form-button {
  background-color: #1B72E8;
  color: white;
  border: none;
  height: 2rem;
  width: 4rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  width: 35%;
}


.demo-login-form-button:hover {
  background-color: #0c60d6;
}


.demo-login-form-button:active {
  background-color: #0a54ba;
}


.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}


.sign-in-form-header {
  margin-top: 2rem;
  margin-bottom: 0;
}


.sign-in-box-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
