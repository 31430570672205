.home-video-card-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 20rem;
    margin: 30px 10px 40px 10px
}

.video-thumbnail {
    position: relative;
    margin-bottom: .1rem;
    width: 330px;
        height: 200px;
}


.card-video-title {
    font-weight: 700;
    font-size: 14px;
    max-width: 85%;
    word-wrap: break-word;
}


.card-video-username {
    color: #606060;
    font-size: 13px;
}


.card-video-views {
    color: #606060;
    font-size: 13px;
    display: flex;
    gap: 3px;
}


.home-video-thumbnail {
    width: 330px;
    height: 200px;
    top: 0;
    border-radius: 12px;
    object-fit: cover;
}


.home-video-card-lower {
    display: flex;
    gap: 10px;
}

.home-user-profile-picture {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-top: 8px;
    border-radius: 50%;
}

.home-video-card-lower-information {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
}
