.upload-video-wrapper {
  height: 90vh;
  width: 70vw;
}


.upload-video-form {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
} 


.user-page-account-info {
  display: flex;
  justify-content: center;
  gap: 15px;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
}

.user-pfp-image-and-upload-button-name {
  display: flex;
  gap: 2rem;
}


.likes-and-my-videos-row {
      display: flex;
        gap: 2rem;
        cursor: pointer;
}



.select-my-videos-active {
  /* background-color: #2a74e35b; */
  border-bottom: 1.5px solid #085ED4;
  padding-bottom: 1rem;
}


.user-page-profile-photo {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
}


.lower-user-page-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}


.upload-modal-input-wrapper {
display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 1.5rem;
  /* flex-wrap: wrap; */
  position: absolute;
  width: 80%;
  top: 10%;
}


#upload-vid-modal-title-textarea {
  height: 3rem;
  font-family: 'Lato', sans-serif;
  padding-left: 8px;
  border-radius: 4px;
  padding-top: 6px;
  border: 1px solid #CCCCCC;
}

#upload-vid-modal-description-textarea {
  height: 5rem;
  padding-top: 6px;
  font-family: 'Lato', sans-serif;
  padding-left: 8px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
}


#upload-vid-modal-category-input {
 height: 1.5rem;
font-family: 'Lato', sans-serif;
padding-left: 8px;
border-radius: 4px;
border: 1px solid #CCCCCC;
}

.upload-video-clipart {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}


.user-page-username-and-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}


.upload-header-and-text {
display: flex;
flex-direction: column;
align-items: center;
gap: 0;
}

.upload-video-text {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
}


.initial-upload-text {
  width: 80%;
  text-align: center;
  color: #606060;
  font-size: 14px;
  margin-bottom: 1.8rem;
}

.edit-delete-modal-user-buttons-video {
  display: flex;
  justify-content: center;
  gap: 20px;
}


.edit-delete-modal-user-buttons-video > button {
  background-color: white;
  border: 1px solid #0A5ED4;
  color: #0A5ED4;
  height: 2rem;
  width: 6rem;
  border-radius: 14px;
  font-family: 'Lato', sans-serif;
  cursor: pointer;
}


.edit-delete-modal-user-buttons-video > button:hover {
  background-color: #0A5ED4;
  color: white;
}

.edit-delete-modal-user-buttons-video > button:active {
  background-color: #4376bc;
}

#modal-open-user-button > button {
  background-color: #0A5ED4;
  border-radius: 16px;
  border: none;
  color: white;
  font-weight: 700;
  height: 2.3rem;
  width: 7rem;
  cursor: pointer;
}


#modal-open-user-button > button:hover {
  background-color: #0356BE;
}


#modal-open-user-button > button:active {
  background-color: #2a74e3;
}


.edit-video-form-modal {
  display: flex;
  /* flex-direction: column; */
}

.edit-video-form {
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 20rem;
  padding: 1rem 2rem
}


#edit-modal-form-header {
  text-align: center;
}


.edit-modal-inputs-wrapper {
  display: flex;
  margin-top: 1.3rem;
  flex-direction: column;
  gap: 1rem;
}

.edit-modal-input-and-button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.edit-modal-inputs-wrapper > input {
  border-radius: 6px;
  border: 1px solid #E5E5E5;
  height: 2rem;
  -webkit-padding-start: 10px;
}


.edit-modal-input-and-button-wrapper > button {
  background-color: #0A5ED4;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  width: 6rem;
  height: 2rem;
  color: white;
  border: none;
  border-radius: 12px;
  align-self: center;
}
/* .main-user-page-container {
  display: flex;
  flex-direction: column;
} */


.edit-video-modal-save-button {
  cursor: pointer;
}

.edit-video-modal-save-button:hover {
  background-color: #0356BE;
}

.edit-video-modal-save-button:active {
  background-color: #2a74e3;
}


.upload-loading-screen-wrapper {
  height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  background: rgb(0, 0, 0)
}

/* .loading-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s infinite linear;
  margin: 0 auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */


.loading-spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #474bff 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#0000 30%, #474bff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}


#modal-download-logo {
  font-size: 60px;
  color: #909090;
  padding: 2rem;
  border-radius: 50%;
  background-color: #f9f9f9;
}


#upload-video-modal-file-input  {
  display: none
}


#fileInput {
  display: none;
}


.custom-file-input {
  background-color: #085ED4;
  color: white;
  padding: .5rem 1.3rem;
  cursor: pointer;
  font-size: 15px;
}


#select-vid-file-to-upload {
  margin-bottom: 0;
}


#your-details-will-be-private {
  margin-top: 0;
  margin-bottom: 20px;
}

.file-input {
  display: none;
}


.upload-modal-file-step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.upload-video-text-header {
align-self: flex-start;
padding-left: 35px;
font-weight: 400;
border-bottom: 1px solid #E5E5E5;
padding-bottom: 20px;
}


.upload-video-main-input-div {
  display: flex;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10rem;
}


#video-upload-error {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 0;
}


.video-upload-step2-name {
  color: #085ED4;
}


#thumbnail-input {
  display: none;
}

.upload-thumbnail-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  align-items: flex-start;
  align-items: center;
  border: 1px dotted #dbdada;
  border-radius: 4px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  /* padding-bottom: px; */
}

#upload-thumbnail-border-button {
  margin-top: 12px;
  color: #7d7d7d;
  text-align: center;
}

#thumbnail-image-file-icon {
  font-size: 26px;
  color: #7d7d7d;
}

#thumbnail-description-text-area {
  margin-top: 0;
  width: 60%;
  color: #606060;
  font-weight: 300;
}

.uploaded-thumbnail-preview {
  height: 200px;
  width: 200px;
  object-fit: contain;
}


.thumbnail-preview-flex-test {
  display: flex;
}

#thumbnail-preview-upload-text {
  margin-bottom: 0;
}


.thumbnail-info-header-p-and-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.thumbnail-rendered-image-preview-info {
      display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 80px;
}


.final-modal-upload-video-button {
        margin-top: 20px;
          width: 12rem;
          height: 4rem;
          align-self: center;
          border-radius: 6px;
          color: white;
          border: none;
          cursor: pointer;
          background-color: #085ED4;
          font-size: 20px;
          font-family: 'Lato', sans-serif;
          font-weight: bold;
}


.final-modal-upload-video-button:hover {
  background-color: #0356BE;
}


.final-modal-upload-video-button:active {
  background-color:#2a74e3
}

