.profile-dropdown {
    position: absolute;
    border: 1px solid #ddd;
    padding: 10px 10px;
    transform: translate(-50px, 30px);
    border-radius: 20px;
    z-index: 1;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    line-height: 4vh;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.logged-out-sign-in-button {
  background-color: rgba(221, 221, 221, 0);
  font-family: 'Lato', sans-serif;
  font-weight: 600px;
  padding: 10px 10px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #085ED4;
  border: .5px solid #085ED4;
  cursor: pointer;
  margin-right: 2rem;
}


.logged-out-sign-in-button:hover {
  background-color: #DEF0FE;
}


#user-nav-pfp {
  cursor: pointer;
}

.logged-out-sign-in-button:active {
  background-color: #C8D8E4;
}

.hidden {
  display: none;
}

.nav-bar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin:  1rem; */
  /* max-height: 3rem; */
  /* margin-bottom: 1.5rem; */
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 5; 
  width: 100vw;
}


.create-video-nav-button {
  display: flex;
  align-items: center;
  gap: 7px;
  background: none;
  border: 1px solid #E5E5E5;
  font-family: 'Lato', sans-serif;
  border-radius: 12px;
  font-weight: 500;
  cursor: pointer;
}


.create-video-nav-button:hover {
  background-color: #E5E5E5;
}


.signed-in-nav-profile-and-create {
  display: flex;
  gap: 20px;
  margin-right: 2rem;
}



.dropdown-top-section {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 5px;
}


.dropdown-name-and-pfp {
  display: flex;
  flex-direction: column;
}


#dropdown-menu-logout-button {
  background-color: #085ED4;
  color: white;
  border-radius: 16px;
  border: none;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  height: 2rem;
  width: 5.5rem;
  cursor: pointer;
}

#dropdown-menu-logout-button:hover {
  background-color: #0456BD;
}


#dropdown-menu-logout-button:active {
  background-color: #016ae9;
}

.my-channel-dropdown-text {
  color: #085ED4;
  cursor: pointer;
}


.my-channel-dropdown-text:hover {
  color: #1671f1;
}


.logo {
    width: 30vh;
}