.all-videos-grid {
    display: flex;
    flex-wrap: wrap;
    justify-self: center;
    gap: 6px;
    width: 85%;
    
}

.home-page-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    border-top: 1px solid #ff66c22a;
    margin-top: 5rem;
}

.home-side-bar-socials {
    margin-right: 2rem;
    margin-left: .5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 15%;
}

.user-videos-link {
    /* border-top: 1px solid #e3e3e3; */
    border-bottom: 1px solid #e3e3e3;
    padding: .8rem;
    margin: .5rem 0;
    font-size: 18px;
   

}

.user-videos-link:hover {
    background-color: #eeeeee;
    border-radius: 12px;
    
}


.home-social-media-icons {
    display: flex;
    gap: 10px;
}


.github-icon-wrapper {
    display: flex;
    padding: 0 .8rem;
    margin: .5rem 0;
    font-size: 18px;
}


.github-icon-wrapper:hover {
    background-color: #EEEEEE;
    border-radius: 8px;
}

.github-a-tag {
 display: flex;
gap: 10px;

}


#github-logo {
    align-self: center;
}

.target-your-videos {
    display: flex;
    align-items: center;
        gap: 10px;
}